import React, { useReducer } from "react"

// Creating context using react context
export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

// Setting the initial state
const initialState = {
  location: "Europe",
  manual: false,
  allProducts: false,
  reccommendations: null,
}

// Setting the reducer and setting the available commands to set the global state
const reducer = (state, action) => {
  switch (action.type) {
    case "LOCATION_EUROPE": {
      return {
        ...state,
        location: "Europe",
      }
    }
    case "LOCATION_ROW": {
      return {
        ...state,
        location: "Europe",
      }
    }
    case "MANUAL_EUROPE": {
      return {
        ...state,
        location: "Europe",
        manual: true,
      }
    }
    case "MANUAL_ROW": {
      return {
        ...state,
        location: "Rest of World",
        manual: true,
      }
    }
    case "SHOW_ALL_PRODUCTS": {
      return {
        ...state,
        allProducts: true,
      }
    }
    case "HIDE_ALL_PRODUCTS": {
      return {
        ...state,
        allProducts: false,
      }
    }
    default:
      throw new Error("Bad action type")
  }
}

// Wrapper for the reducer and provider that will wrap around all other components
const GlobalContextProvider = ({ children }) => {
  // Setting the state and dispatch using react hook useReducer
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
