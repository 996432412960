// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-js": () => import("./../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calculator-js": () => import("./../src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-distributors-js": () => import("./../src/pages/distributors.js" /* webpackChunkName: "component---src-pages-distributors-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-stockist-js": () => import("./../src/pages/stockist.js" /* webpackChunkName: "component---src-pages-stockist-js" */),
  "component---src-pages-stockists-index-js": () => import("./../src/pages/stockists/index.js" /* webpackChunkName: "component---src-pages-stockists-index-js" */),
  "component---src-pages-stockists-old-index-js": () => import("./../src/pages/stockistsOld/index.js" /* webpackChunkName: "component---src-pages-stockists-old-index-js" */)
}

